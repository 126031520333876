.social {
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.social li {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.social li:not(:first-child) {
  margin: 0 0 0 8px;
}

.social a {
  display: block;
  text-decoration: none;
  transition: transform ease-in-out 400ms, background-color ease-in-out 400ms;
  border-radius: 3px;
  background-color: var(--background-color);
}

.social a:hover {
  transition: transform ease-out 100ms, background-color ease-out 100ms;
  transform: scale(1.075);
  background-color: var(--clear-color);
}

.social svg {
  display: block;
  width: 26px;
  height: 26px;
  padding: 8px;
}
