.PageHeader {
  margin: 60px 0;
}

.topBar {
  margin-top: -60px;
  background-color: var(--brand-background-color);
  height: 100px;
  overflow: visible;
  margin-bottom: 130px;
}

.coverImage {
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  border: 3px solid var(--background-color);
}

.PageHeader h1 {
  text-align: center;
  margin: 30px 0;
}

.content {
  margin: 0 auto;
  width: 550px;
}

@media screen and (max-width: 590px) {
  .content {
    margin: 0 20px;
    width: auto;
  }
}
