.Form {
  width: 500px;
  margin: 60px auto;
}

.Form form label {
  margin-bottom: 8px;
}

.formSection {
  border-radius: 10px;
  background-color: var(--background-color);
  padding: 40px 64px 30px;
}

.errorBox {
  background-color: var(--background-color);
  color: var(--error-color);
  box-sizing: border-box;
  margin-top: 1px;
  overflow: hidden;
}

.errorBox p {
  margin: 12px 64px;
}

.errorBox.hasError {
  transition: max-height 500ms ease-in-out;
  max-height: 200px;
  overflow: scroll;
}

.errorBox.noError,
.errorBox.errorHidden {
  max-height: 0;
}

.formSection,
.errorBox {
  border-radius: 8px;
}

.formSection h1 {
  margin: 0 0 12px 0;
}

.hint {
  margin: 0 0 20px 0;
  font-size: 0.75em;
}

.formSection a {
  color: inherit;
}

.buttons {
  margin: 20px 0 0;
  text-align: center;
}

.buttons input,
.buttons button {
  margin: 0 6px;
}

.buttons button[type="submit"] {
  padding-left: 2em;
  padding-right: 2em;
}

.footerLink {
  margin-top: 12px;
  font-size: 0.8rem;
  color: var(--gray-1);
}

.footerLink a {
  color: inherit;
}

.finePrint {
  text-align: center;
  color: var(--background-color);
  font-size: 0.75em;
  max-width: 100%;
  margin: 20px;
}

@media (max-width: 500px) {
  .Form {
    width: 100vw;
  }

  .formSection {
    padding-left: 12px;
    padding-right: 12px;
  }
}
