.footnote {
  border-top: 1px solid var(--gray-2);
  padding-top: 12px;
  color: var(--gray-2);
  font-size: 0.8em;
}

.footnote svg {
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.6em;
  vertical-align: text-bottom;
}
