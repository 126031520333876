@import "colors.css";

html,
body {
  background-color: var(--background-color);
  --avatar-size: 64px;
}

* {
  outline-color: var(--brand-color);
}

body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: var(--text-color);
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
}

nav.main {
  display: block;
  background-color: var(--brand-background-color);
}

section {
  display: block;
  margin: 60px 0;
  background-color: var(--background-color);
}

section > div > h2 {
  margin-top: 0;
}

footer {
  display: block;
  margin: auto 0 0;
  background-color: var(--brand-background-color);
}

h1,
h2,
h3,
blockquote,
nav.main li > a {
  font-family: "Rockwell", "Sanchez", serif;
}

h1 {
  font-size: 1.75rem;
  font-weight: bold;
}

h2 {
  font-size: 1.5rem;
  font-weight: normal;
}

h3 {
  font-size: 1rem;
  font-weight: normal;
}

h1 a,
h2 a,
h3 a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

h1 a:hover,
h2 a:hover,
h3 a:hover {
  color: var(--tint-color);
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child) {
  margin-top: 1.5em;
}

hr {
  border: 1px solid var(--brand-background-color);
}

p {
  max-width: 35em;
  line-height: 1.4em;
}

.caption {
  font-size: 0.8rem;
  color: var(--gray-1);
}

a {
  color: var(--tint-color);
}

a:hover {
  color: var(--tint-hover-color);
}

picture {
  display: inline-block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

blockquote {
  color: var(--text-color);
  text-align: center;
  font-size: 1.3rem;
  line-height: 2rem;
}

cite {
  font-weight: bold;
  font-style: normal;
}

cite::before {
  content: " —\00a0";
}

input,
textarea,
select {
  font-size: 16px;
  font-family: inherit;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}
