ul.paymentMethods {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 12px 0;
  padding: 0;
  gap: 8px;
}

ul.paymentMethods li {
  display: inline-block;
}

ul.paymentMethods img,
ul.paymentMethods svg {
  display: block;
}
