.Alert {
  color: var(--text-color);
  display: inline-flex;
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  border: 1px solid var(--background-color);
  margin-bottom: 1em;
}

.pointer {
  cursor: pointer;
}

.defaultCursor {
  cursor: default;
}

.Alert svg {
  display: block;
  margin-right: 0.5em;
}

.info {
  background-color: var(--alert-info-color);
}

.confirm {
  background-color: var(--alert-confirm-color);
}

.error {
  background-color: var(--alert-error-color);
}
