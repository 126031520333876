.OrderSummary {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  table-layout: fixed;
}

.OrderSummary tbody {
  border-top: 2px solid var(--gray-1);
}

.OrderSummary tr {
  vertical-align: baseline;
}

.OrderSummary td {
  line-height: 1.8em;
  padding: 10px 0;
}

.OrderSummary .itemRow td {
  padding: 4px 0;
}

.OrderSummary .itemRow:first-child td {
  padding-top: 12px;
}

.OrderSummary .itemRow:last-child td {
  padding-bottom: 12px;
}

.OrderSummary tbody.shipping {
  border-top: 1px solid var(--gray-1);
}

.OrderSummary tfoot {
  color: var(--tint-color);
  border-top: 2px solid var(--gray-1);
}

.notes {
  display: inline-block;
  width: 100%;
  font-size: 0.7rem;
  color: var(--gray-1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.numeral {
  text-align: right;
  padding-left: 1em;
}

/* Total cell */
.OrderSummary tfoot .numeral {
  font-size: 18px;
  font-weight: bold;
}
