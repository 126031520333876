.ImageWithOverlay {
  position: relative;
}

.caption {
  color: var(--overlay-text-color);
  box-sizing: border-box;
  position: absolute;
  bottom: 1px;
  left: 1px;
  right: 1px;
  overflow: hidden;
  padding-top: 2rem;
  background-image: linear-gradient(rgb(0, 0, 0, 0), rgb(0, 0, 0, 0.4));
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 3px 3px;
  transition: max-height 400ms ease-in-out;
}

a:hover .caption {
  max-height: 100%;
}

.caption h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0 7px 2px;
}

@media screen and (max-width: 465px) {
  .caption {
    padding-top: 1rem;
  }
}

@media (hover: none) {
  .caption {
    max-height: 100%;
  }
}
