.banner {
  background-color: #ffbf00;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cover,
.content {
  margin: 20px;
}

.cover {
  transform: rotateZ(-5deg);
  margin-top: -4px;
}

.cover img {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  border: 1px solid var(--background-color);
}

.banner small {
  text-transform: uppercase;
  color: var(--clear-color);
}

.banner h2 {
  color: var(--text-color);
}



@media (max-width: 400px) {
  .cover,
  .content {
    margin: 10px;
  }

  .banner {
    flex-direction: column;
  }

  .cover img {
    max-width: 80%;
    max-height: 200px;
  }
}
