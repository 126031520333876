.ArchiveHeader {
  display: flex;
  align-items: flex-end;
}

.ArchiveHeader > div {
  flex-grow: 1;
}

.ArchiveHeader h1 {
  margin-top: 0;
}

.imageWrapper {
  text-align: right;
  margin-left: 20px;
}

.image {
  max-width: 185px;
  max-height: 185px;
  width: auto;
  height: auto;
}

@media (max-width: 400px) {
  .imageWrapper {
    display: none;
  }
}
