.BookCard {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.cover {
  margin: 0 40px 0 0;
}

.BookCard h3 {
  margin: 0;
}

.BookCard small {
  color: var(--gray-1);
}

.BookCard small span {
  display: inline-block;
}

.BookCard small span:not(:first-child) {
  margin: 0;
}
.BookCard small span:not(:first-child)::before {
  content: "•";
  margin: 0 0.7em;
}

.BookCard p {
  margin-bottom: 0;
}

.buttons {
  margin-top: 1em;
  display: flex;
  align-items: center;
  gap: 8px;
}

.soldOutLabel {
  color: var(--gray-1);
  font-variant: all-small-caps;
  margin-right: 0.75em;
}

@media (max-width: 650px) {
  .cover img {
    width: 140px;
  }
}

@media (max-width: 480px) {
  .BookCard {
    flex-direction: column;
    align-items: stretch;
  }

  .cover {
    margin: 0 0 30px;
  }

  .cover img {
    width: 200px;
  }
}

@media (max-width: 240px) {
  .cover img {
    width: 100%;
  }
}
