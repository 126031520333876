.Button {
  display: inline-block;
  background-color: var(--tint-color);
  color: var(--background-color);
  padding: 12px 9px;
  margin: 0;
  min-width: 44px;
  border-radius: 6px;
  border: 1px solid var(--tint-color);
  box-shadow: none;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  outline-color: var(--tint-color);
}

.Button.compact {
  font-size: 13px;
  padding: 7px 9px;
  margin: 0 0.33em 0.13em 0;
}

label.Button input[type="file"] {
  display: none;
}

a.Button {
  text-decoration: none;
}

a.Button:not(.disabled):hover {
  color: var(--background-color);
}

.Button.primary:not(.disabled):not(:disabled):hover {
  color: var(--background-color);
  background-color: var(--tint-hover-color);
}

.Button:disabled,
.Button.disabled {
  background-color: var(--gray-2);
  border-color: var(--gray-2);
  color: var(--gray-3);
  cursor: not-allowed;
}

.Button svg {
  vertical-align: middle;
}

.Button.danger {
  background-color: var(--error-color);
  border-color: var(--error-color);
}

.Button.primaryAlternate {
  background-color: var(--alternate-tint-color);
  border-color: var(--alternate-tint-color);
}

.Button.secondary,
.Button.small {
  background-color: var(--background-color);
  color: var(--tint-color);
  border-color: var(--brand-background-color);
}

a.Button.secondary:not(.disabled):hover,
a.Button.small:not(.disabled):hover {
  color: var(--tint-hover-color);
}

.Button.link {
  margin: 0;
  padding: 0;
  display: inline;
  background-color: initial;
  color: var(--tint-color);
  border: none;
  text-decoration: underline;
}

.Button.borderless {
  background-color: initial;
  border: none;
  color: var(--tint-color);
  margin: 1px;
}

.Button.loadable {
  padding-left: 36px;
  padding-right: 36px;
  position: relative;
}

.Button .loader {
  position: absolute;
  left: 7px;
  margin-top: -3px;
}
