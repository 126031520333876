.ThumbLink {
  display: block;
}

.Thumb {
  display: block;
}

.Thumb picture {
  display: block;
}

.Thumb.draft h2 {
  text-decoration: line-through;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
}

.Thumb.deleted h2 {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
}

.Thumb.deleted img,
.Thumb.draft img {
  opacity: 0.25;
}

.Thumb img {
  display: block;
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  border: 1px solid var(--strip-border-color);
  border-radius: 4px;
}

.Thumb h2 {
  font-size: 20px;
}

.Thumb p {
  margin: 4px 8px 0;
}

@media screen and (max-width: 760px) {
  .Thumb img {
    max-width: 100%;
    max-height: 160px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 650px) {
  .Thumb h2 {
    font-size: 1em;
  }

  .Thumb p {
    font-size: 0.8em;
  }
}
