:root {
  --clear-color: white;
  --text-color: black;
  --overlay-text-color: #fbfaf7;
  --brand-background-color: #73c2de;
  --tint-color: #17a9d9;
  --tint-hover-color: #188db4;
  --alternate-tint-color: #f96854;
  --background-color: #fbfaf7;
  --background-color-transparent: rgba(251, 250, 247, 0.85);
  --gray-3: #e1e1e1;
  --gray-2: #9f9f9f;
  --gray-1: #696969;
  --strip-border-color: black;
  --error-color: #ff1a39;
  --shadow-color: var(--tint-color);

  --alert-confirm-color: #96d69f;
  --alert-info-color: #73c2de;
  --alert-error-color: #d69696;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --clear-color: black;
    --text-color: white;
    --brand-background-color: #4697b4;
    --tint-hover-color: rgb(21, 157, 202);
    --background-color: rgb(31, 31, 28);
    --background-color-transparent: rgba(31, 31, 28, 0.85);
    --gray-3: #5f5f5f;
    --gray-2: #777777;
    --gray-1: #d6d6d6;
    --shadow-color: rgba(0, 0, 0, 0.4);
  }
} */
