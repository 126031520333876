.Footer {
  color: var(--clear-color);
  margin: 60px 0;
}

@supports (margin: max(0px)) {
  .Footer {
    margin-bottom: max(60px, env(safe-area-inset-bottom));
  }
}

.content {
  display: flex;
  justify-content: space-between;
}

.Footer h3 {
  margin-top: 0;
}

.Footer .links ul {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.Footer a {
  color: var(--background-color);
}

.SubscribeForm input {
  border: none;
  background-color: var(--background-color);
  border-radius: 3px;
  width: 100%;
  height: 44px;
  line-height: 44px;
  font-size: 18px;
  padding: 0 10px;
  box-sizing: border-box;
  transition: background-color 0.25s ease-in-out;
}

.SubscribeForm input:hover,
.SubscribeForm input:focus {
  background-color: var(--clear-color);
}

.SubscribeForm input::placeholder {
  color: var(--brand-background-color);
}

li.version {
  color: var(--tint-color);
  margin-top: 0.5rem;
  font-size: 0.8rem;
}
li.version::selection {
  color: var(--background-color);
  background-color: var(--tint-color);
}

@media screen and (max-width: 650px) {
  .Footer {
    margin: 40px 0;
  }

  @supports (margin: max(0px)) {
    .Footer {
      margin-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }

  .content {
    flex-direction: column;
    flex-flow: wrap;
  }

  .subscribe {
    order: 1;
    width: 100%;
    margin: 0 0 30px 0;
  }

  .links {
    order: 2;
  }

  .social {
    order: 3;
  }
}
