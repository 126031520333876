.header {
  margin: 0 0 20px;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
}
.header h1,
.header .episode,
.header h2,
.header .date {
  display: inline-block;
}
.header h1 {
  font-weight: bold;
  margin: 0 0.5em 0 0;
  font-size: 46px;
  flex-grow: 0;
}
.header .episode {
  flex-grow: 1;
  display: inline-flex;
}
.header h2 {
  font-weight: normal;
  font-size: 32px;
  margin: 0;
  margin: 0 120px 0 0;
  line-height: 40px;
}
.header .date {
  margin: 0 0 0 auto;
  line-height: 40px;
  flex-shrink: 0;
}

.header.deleted h2,
.header.draft h2 {
  color: var(--gray-2);
}

.status {
  display: inline-block;
  content: " ";
  margin-left: 0.5rem;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: gray;
  vertical-align: middle;
}
.status:global(.deleted) {
  background-color: #e35169;
}
.status:global(.draft) {
  background-color: #a2b5cd;
}
.status:global(.published) {
  background-color: #3399ff;
}
.status:global(.ready) {
  background-color: #2ecda7;
}

.panels {
  position: relative;
  width: inherit;
}

.panels > a {
  text-decoration: none;
  display: block;
}

.panels picture {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
  line-height: 0;
}

.panel {
  display: block;
  width: 100%;
  height: auto;
  margin: 8px -2px;
  border: 2px solid var(--strip-border-color);
  border-radius: 3px;
  box-sizing: content-box;
  background-color: var(--gray-2);
}

.panels :last-child .panel {
  margin-bottom: 0;
}

.belowStrip {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 12px 0;
}

.text {
  max-width: 100%;
  overflow: hidden;
}

.text img {
  max-width: 100%;
  height: auto;
}

.bookLink {
  display: flex;
  font-size: 0.8em;
  text-decoration: none;
  text-align: right;
  margin-top: 30px;
  margin-left: 60px;
  flex-shrink: 0;
}

.bookLink img {
  vertical-align: top;
  width: 120px;
  height: auto;
  margin: -8px 0 -8px 12px;
  transform: rotate(-3deg);
  box-shadow: 5px 5px 0 var(--shadow-color);
}

.previewCTA {
  padding: 33.5% 0;
  background-color: lavender;
}

@media (prefers-color-scheme: dark) {
  .previewCTA {
    background-color: darkslategrey;
  }
}

.previewCTA .content {
  margin: 0 20px;
  display: flex;
  place-content: center;
  align-items: baseline;
}

.previewCTA .content button {
  flex-shrink: 0;
}

@media screen and (max-width: 500px) {
  .header {
    margin: 0 20px 16px;
    flex-direction: column;
  }

  .header h1 {
    font-size: 28px;
  }

  .header .episode {
    flex-direction: column;
  }

  .header h2 {
    font-size: 24px;
  }

  .header h2,
  .header .date {
    line-height: 30px;
    margin: unset;
  }

  .panels {
    margin: 0 4px;
  }

  .panel {
    margin: 2px -2px;
  }

  .belowStrip {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .bookLink {
    align-self: flex-end;
  }

  .bookLink,
  .text {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .bookLink {
    margin-bottom: 20px;
  }
}

@media (max-width: 200px) {
  .header h1,
  .header h2 {
    font-size: 1.2em;
  }
  .header {
    margin: 10px;
    overflow: hidden;
  }
}
