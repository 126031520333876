.Account {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.Account h1 {
  margin-top: 0;
}

.menu {
  border-right: 1px solid var(--gray-3);
  padding-right: 30px;
  margin-right: 60px;
}

.content {
  width: 450px;
}

.menuItems {
  list-style-type: none;
  padding: 0;
  text-align: right;
}

.menuItems a {
  color: var(--text-color);
  line-height: 44px;
  display: block;
}
.menuItems a.active {
  color: var(--tint-color);
}

.ProfilePicture .buttons {
  margin-top: 1em;
}

@media (max-width: 650px) {
  .Account {
    flex-direction: column;
  }

  .menu {
    border-right: none;
  }

  .menuItems {
    text-align: initial;
  }

  .content {
    width: 100%;
  }
}

@media (min-width: 651px) {
  .Account h2:first-child {
    margin-top: 0;
  }
}
