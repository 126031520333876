.banners {
  display: flex;
}

.banner:not(:first-child) {
  margin: 0 0 0 20px;
}

.banner h2 a:hover {
  color: inherit;
}

.banner {
  flex-basis: 0;
  flex-grow: 1;
}

@media (max-width: 500px) {
  .banners {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
