.container {
  max-width: 880px;
  margin: 0 auto;
}

.content {
  /* Strip controls stick out 60px + 20px margin */
  margin: 0 80px;
}

@supports(margin: max(0px)) {
  .content {
    margin-left: calc(60px + max(20px, env(safe-area-inset-left)));
    margin-right: calc(60px + max(20px, env(safe-area-inset-right)));
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .content {
    /* Strip controls stick out 52px, touching sides */
    margin: 0 52px;
  }

  @supports(margin: max(0px)) {
    .content {
      margin-left: calc(52px + env(safe-area-inset-left));
      margin-right: calc(52px + env(safe-area-inset-right));
    }
  }
}

@media screen and (max-width: 500px) {
  .content {
    margin: 0;
  }

  .content > p {
    margin-left: 20px;
    margin-right: 20px;
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }

  @supports(margin: max(0px)) {
    .content > p {
      margin-left: max(20px, env(safe-area-inset-left));
      margin-right: max(20px, env(safe-area-inset-right));
    }
  }
}
