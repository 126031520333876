.FavoriteButton {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.FavoriteButton button {
  display: inline-block;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0 0 0 0;
  font: inherit;
  color: inherit;
  line-height: 0;
  cursor: pointer;
  transition: color 150ms ease;
}

.FavoriteButton.favorited button,
.FavoriteButton button:hover {
  color: #ff1a39 !important;
}

.FavoriteButton .badge {
  cursor: default;
  margin-left: 4px;
}
