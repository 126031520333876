.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.editor {
  text-align: center;
  margin: 0 1em 1em 0;
}

.slider {
  width: 100%;
}
