.Autocomplete input {
  border: 1px solid var(--tint-color);
  padding: 0.25rem 0.5rem;
  width: 300px;
  outline: none;
}

.NoSuggestions,
.Suggestions {
  position: absolute;
  z-index: 999;
}

.NoSuggestions {
  color: #999;
  padding: 0.5rem;
}

.Suggestions {
  background-color: var(--background-color);
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.Suggestions li {
  padding: 0.5rem;
}

.active,
.Suggestions li:hover {
  color: var(--clear-color);
  background-color: var(--tint-color);
  cursor: pointer;
  font-weight: 700;
}

.Suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

@media (max-width: 500px) {
  .Autocomplete input,
  .Suggestions {
    max-width: 60vw;
  }
}
