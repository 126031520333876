.soldOutLabel {
  color: var(--error-color);
  font-size: 18px;
  margin-right: 0.75em;
  font-variant: all-small-caps;
}

.price {
  color: var(--tint-color);
  font-size: 18px;
  font-weight: bold;
  display: block;
}
