.Comments {
  font-size: 0.9em;
}

@media screen and (max-width: 500px) {
  .Comments {
    margin: 0 1rem;
  }
}

.Comments > .Comment {
  margin: 1rem 0;
  border-bottom: 1px solid var(--gray-3);
}

.replies .Comment {
  margin-top: 0;
  padding-bottom: 0;
}

.replies .Comment:last-child {
  margin-bottom: 0;
}

.body {
  display: flex;
  flex-direction: row;
}

.avatarContainer {
  margin-right: 16px;
  width: 64px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body:not(:last-child) .conversationLine,
.replies .Comment:not(:last-child) .conversationLine {
  display: block;
  width: 3px;
  flex-grow: 1;
  background-color: var(--gray-3);
  margin: 4px 0;
  border-radius: 2px;
}

.content {
  max-width: 40rem;
  margin-bottom: 20px;
}

.name {
  font-weight: bold;
}

.text {
  margin: 0.5em 0 0.75em;
  line-height: 1.5em;
  word-break: break-word;
}

.text p {
  margin: 0.25em 0;
}

.text a {
  color: inherit;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--gray-1);
  font-size: 0.9em;
  column-gap: 24px;
}

.footer button svg {
  overflow: visible;
}

.body:hover button {
  color: var(--text-color);
}

.replies {
  display: block;
}

.replies .avatarContainer img {
  width: 48px;
  height: 48px;
}

.replies .avatarContainer {
  --avatar-size: 48px;
}

.replies .name,
.replies .text {
  font-size: 0.9em;
}

.verifiedIcon {
  color: var(--tint-color);
  display: inline-block;
  margin: 0 0.3em;
  position: relative;
  top: 0.15em;
}

.replyButton {
  display: inline-block;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0 0 0 0;
  font: inherit;
  color: inherit;
  line-height: 0;
  cursor: pointer;
  transition: color 150ms ease;
}

.replyButton:hover,
.replyButton:active,
.replyButton.active {
  color: var(--tint-color) !important;
}

.buttons {
  margin: 20px 0;
}
