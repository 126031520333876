.outer {
  perspective: 100px;
}

.lift {
  transition: transform 150ms ease-in-out;
  transform-style: preserve-3d;
}

.inner {
  border-radius: 4px;
  box-shadow: 0 0 0 0 var(--shadow-color);
  transition-property: box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

/* Active state: push down */

a:active .aboveCaption .lift,
a:active .lift {
  transform: none;
}

a:active .inner {
  box-shadow: 2px 2px var(--shadow-color);
}

/* Hover state: lift up */

:global(.active) .lift,
a:hover:not(:active) .lift {
  transform: translate3d(0, 0, 5px);
}

:global(.active) .aboveCaption .lift,
a:hover:not(:active) .aboveCaption .lift {
  transform: translate3d(0, -4px, 5px);
}

:global(.active) .inner,
a:hover:not(:active) .inner {
  box-shadow: 6px 6px var(--shadow-color);
}
