.NavigationMenu {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin: 0 30px;
}

.scroller {
  border-right: 1px solid transparent;
  overflow-x: auto;
  overflow-y: hidden;
}

ol.pages,
ul.personal {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: baseline;
}

ul.personal {
  flex-direction: row-reverse;
}

.NavigationMenu li > a {
  display: block;
  color: var(--clear-color);
  text-decoration-color: transparent;
}

a.text {
  font-size: 18px;
  transition: text-decoration-color 100ms ease-in-out;
}

a.text span {
  padding: 30px 10px;
  border-radius: 5px;
}

/* Make menu items interactible above modal backdrop  */

.NavigationMenu li {
  z-index: 4;
}

/* Hover state */

.NavigationMenu li > a:hover,
.NavigationMenu li > a:focus {
  outline: 0;
}

.NavigationMenu li > a:hover {
  text-decoration-color: initial;
}

.NavigationMenu li > a:not(:hover):focus {
  text-decoration: underline;
  text-decoration-color: var(--tint-color);
}

/* Active state adds a 1px border below the label */

.NavigationMenu a.text span {
  display: block;
  line-height: 30px;
}

.NavigationMenu a.active span {
  text-decoration: underline;
}

@media (max-width: 760px) {
  .NavigationMenu {
    margin: 0;
  }

  .scroller {
    border-right-color: var(--tint-color);
    transition: border-color 500ms ease-in-out;
  }

  .scroller li:first-child a.text span {
    padding-left: 20px;
  }

  .scroller li:last-child a.text span {
    padding-right: 20px;
  }

  a.text span {
    padding: 12px 10px;
    border-radius: 5px;
  }
}
