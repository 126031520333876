.OrderItem {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 20px 0;
}

.OrderItem h2 {
  margin: 0;
}

.imageWrapper {
  width: 120px;
  margin-right: 20px;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.imageWrapper picture {
  display: block;
}

.imageWrapper .thumb {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  background-color: var(--brand-background-color);
  box-shadow: 5px 5px 0 var(--shadow-color);
}

.details {
  flex-wrap: wrap;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: baseline;
}

.itemDescription {
  flex-basis: 400px;
  flex: 3 3;
  margin-right: 40px;
}

.quantityDetails {
  margin-right: 20px;
  flex: 0 0;
}

.quantityDetails select {
  font-size: 0.8em;
}

.priceDetails {
  text-align: right;
  flex: 1 1;
  flex-basis: 0;
}

.notesEditor {
  font-size: 0.8rem;
}

.notesEditor ol {
  padding: 0 0 0 22px;
  margin-bottom: 8px;
}

.noteInput {
  box-sizing: border-box;
  margin: 1px 8px 1px 1px;
  width: 100%;
}

.noteInput.singleInput {
  margin-bottom: 8px;
}

li .noteInput {
  margin: 1px 0;
}

@media (max-width: 550px) {
  .imageWrapper {
    display: none;
  }

  .OrderItem h2 {
    font-size: 1rem;
  }

  .quantityDetails {
    margin-right: 12px;
  }

  .noteInput {
    width: 100%;
  }
}
