.ActionModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(64, 50, 43, 0.6);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  pointer-events: none;
}

.opened .backdrop {
  opacity: 1;
  pointer-events: all;
}

.contents {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  place-content: center;
  transform: translateY(-100%);
  transition: transform 250ms ease-in-out, opacity 150ms ease-in-out;
  opacity: 0;
}

.opened .contents {
  transform: translateY(0);
  opacity: 1;
}
