.EmptyMessage {
  font-size: 1.5em;
  margin: 60px 20px;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  color: var(--tint-color);
  text-align: center;
}

.EmptyMessage h1, .EmptyMessage p {
  margin: 0;
}
