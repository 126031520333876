.SeriesGallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  margin: 0;
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);
}

a.SeriesCard {
  display: block;
  text-decoration: none;
  color: inherit;
  padding: 2em 2em 1em 2em;
  text-align: center;
}

.SeriesCard img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.SeriesCard h2 {
  margin: 1em 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SeriesCard p {
  margin: 4px 0 12px;
}

.SeriesCard:nth-child(9n+1) { background-color: #F0DADA; }
.SeriesCard:nth-child(9n+2) { background-color: #C6CEBF; }
.SeriesCard:nth-child(9n+3) { background-color: #54C0BA; }
.SeriesCard:nth-child(9n+4) { background-color: #DAEAF0; }
.SeriesCard:nth-child(9n+5) { background-color: #6C8553; }
.SeriesCard:nth-child(9n+6) { background-color: #E7C49D; }
.SeriesCard:nth-child(9n+7) { background-color: #F2DE96; }
.SeriesCard:nth-child(9n+8) { background-color: #BFBFBF; }
.SeriesCard:nth-child(9n+9) { background-color: #DDE0C2; }

@media (max-width: 769px) {
  .SeriesCard h2 {
    font-size: 1.3em;
  }
}

@media (max-width: 280px) {
  .SeriesGallery {
    grid-template-columns: 1fr;
  }

  a.SeriesCard {
    padding: 10%;
  }
}
