.section {
  border-top: 2px solid #f2de96;
  padding: 30px 0;
}

.booksGallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-auto-flow: row dense;
  column-gap: 60px;
  row-gap: 40px;
  align-items: start;
  justify-items: start;
  justify-content: space-between;
}

@media (max-width: 650px) {
  .section {
    padding: 30px 0;
  }
}

@media (max-width: 480px) {
  .booksGallery > div:not(:last-child) {
    border-bottom: 2px solid var(--brand-background-color);
    padding-bottom: 30px;
  }

  .booksGallery {
    justify-items: stretch;
  }
}

@media (max-width: 400px) {
  .booksGallery {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
