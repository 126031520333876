.IconMenuButton {
  color: var(--clear-color);
  display: inline-block;
  position: relative;
  vertical-align: middle;
  top: -2px;
}

.button {
  display: block;
  cursor: pointer;
  padding: 0 6px;
  color: var(--clear-color);
}

.button:hover {
  color: var(--clear-color);
}

.button .background {
  display: block;
  padding: 3px;
  border-radius: 50%;
  border: 1px solid transparent;
}

.button img {
  display: block;
  box-sizing: border-box;
  border: 1px solid var(--clear-color);
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.button svg {
  display: block;
  width: 16px;
  height: 16px;
  margin: 4px;
}

.button svg,
.button img {
  position: relative;
}

.button:hover,
.button:focus {
  outline: none;
}
.button:hover .background {
  border-color: var(--clear-color);
}
.button:hover img {
  border-color: transparent;
}
.button:hover .badge {
  border-color: var(--clear-color);
  background-color: var(--clear-color);
  color: var(--tint-color);
}

.button:not(:hover):focus .background {
  border-color: var(--tint-color);
}

/* Popup Menu */

.menu {
  position: absolute;
  left: 0;
  top: 34px;
}

.menu ul {
  margin: 0;
  padding: 4px;
  list-style-type: none;
  background-color: var(--tint-color);
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.menu li {
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
  white-space: nowrap;
  font-size: 13px;
}

.menu li,
.menu li a {
  color: var(--clear-color);
  text-decoration: none;
}

.menu li > div {
  padding: 7px 4px;
  border-radius: 2px;
}

.menuItem {
  cursor: pointer;
}

.readOnly {
  cursor: default;
  color: var(--gray-3);
}

.menuItem:hover,
.menuItem:focus {
  outline: 0;
  background-color: var(--background-color);
  color: var(--text-color);
}
.menuItem:hover a,
.menuItem:focus a {
  color: var(--text-color);
}

.badge {
  position: absolute;
  display: block;
  font-family: sans-serif;
  font-size: 0.7em;
  width: 14px;
  line-height: 14px;
  border-radius: 50%;
  text-align: center;
  right: 4px;
  top: -2px;
  border: 1px solid var(--tint-color);
  background-color: var(--tint-color);
}

@media (max-width: 760px) {
  li:not(:first-child) .button {
    padding-left: 3px;
  }

  li:not(:last-child) .button {
    padding-right: 3px;
  }

  .menu {
    left: auto;
    right: 0;
  }
}
