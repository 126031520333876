.banner h2,
.banner p {
  margin: 8px 0;
}

.button {
  margin: 12px 0 !important;
}

.banner.withImageBackground {
  padding: 20px;
}

@media (max-width: 350px) {
  .banner.withImageBackground {
    padding-bottom: 68px;
  }
}
