.CommentForm {
  /* Variable: */
  --corner-radius: 3px;

  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}

.CommentForm form {
  display: block;
  flex-grow: 1;
  max-width: min(35em, 100%);
}

.fields {
  background-color: var(--clear-color);
  border: 1px solid var(--gray-2);
  border-radius: var(--corner-radius);
  display: flex;
  flex-direction: column;
}

.fields input,
.fields textarea {
  background: none;
  border: none;
  outline: 0;
  padding: 8px;
}

.avatar a {
  text-decoration: none;
}

.nameBox {
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid var(--gray-2);
}

.nameBox p {
  margin: 8px;
}

.nameField {
  flex-grow: 1;
  border-radius: var(--corner-radius) 0 0 0;
}

.textField {
  border-radius: 0 0 var(--corner-radius) var(--corner-radius);
}

.nameLoginLink {
  margin: 0 8px;
  flex-grow: 0;
  font-size: 12px;
  color: var(--gray-1);
}

.fields textarea {
  resize: none;
}

.buttons {
  margin-top: 8px;
}

.error {
  color: var(--error-color);
}

@media screen and (max-width: 435px) {
  .nameBox {
    flex-direction: column-reverse;
    align-items: stretch;
  }
  .nameLoginLink {
    padding: 8px 0 4px;
  }
}

@media screen and (max-width: 319px) {
  .avatar {
    display: none;
  }
}

@media (min-width: 320px) {
  .nameBox {
    display: none;
  }
}
