.orderItems {
  margin: 60px 0;
  padding: 0;
  list-style-type: none;
}

.buttonsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonsRow button, .buttonsRow a {
  margin-bottom: 0;
}

.buttonsRow button:last-child,
.buttonsRow a:last-child {
  margin-right: 0;
}
