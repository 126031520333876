.container {
  margin: 0 40px;
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);
}

@supports (margin: max(0px)) {
  .container {
    margin-left: max(40px, env(safe-area-inset-left));
    margin-right: max(40px, env(safe-area-inset-right));
  }
}

.readableWidth {
  max-width: 720px !important;
  margin: 0 auto;
}

@media (max-width: 760px) {
  .container {
    margin: 0 20px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1360px;
    margin: 0 auto;
  }
}
