.BookThumb, .placeholder {
  background-color: var(--brand-background-color);
  width: 170px;
  height: auto;
  box-shadow: 7px 7px 0 var(--shadow-color);
  display: block;
}

.placeholder {
  text-align: center;
}

.placeholder svg {
  width: 25%;
  height: auto;
  margin: 47px 0;
  color: var(--background-color);
}
