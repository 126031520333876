.About h1,
.About h2 {
  margin-top: 0;
}

.main {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.main .text {
  flex-shrink: 1;
}

.About p:first-child {
  margin-top: 0;
}

.imageWithByline {
  margin-right: 60px;
  flex-grow: 0;
  flex-shrink: 0;
}

.imageWithByline img {
  display: block;
  max-width: 100%;
  max-height: 60vh;
  width: auto;
  height: auto;
}

.imageWithByline picture {
  display: block;
}

.byline {
  color: var(--gray-1);
  font-variant: all-small-caps;
  margin-top: 0.25em;
}

.contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
}

@media (max-width: 650px) and (min-width: 301px) {
  .About,
  .contact {
    align-items: stretch;
  }

  .main {
    display: block;
  }

  .imageWithByline {
    max-width: 40%;
    float: left;
    margin: 0 30px 30px 0;
  }

  .main .text {
    padding-left: 20%;
  }
}

@media (max-width: 300px) {
  .main {
    flex-direction: column-reverse;
    align-items: center;
  }

  .imageWithByline {
    margin-right: unset;
  }
}
