div:global(.additional-navigation) {
  display: none;
}

.controlsContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.StripControls {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -60px;
  padding: 30px 0;
  color: var(--gray-3);
  pointer-events: none;
}

.navButton {
  background: none;
  cursor: pointer;
  color: var(--tint-color);
  border: 0;
  pointer-events: auto;
  display: block;
  font-size: 2rem;
  padding: 6px;
  border-radius: 4px;
  outline: 0;
}

.navButton:disabled {
  cursor: unset;
  color: unset;
}

@media (hover: hover) {
  .navButton:not(:disabled):hover {
    background-color: var(--tint-color);
    color: var(--background-color);
  }
}
@media (hover: none) {
  .navButton:not(:disabled) {
    background-color: var(--tint-color);
    color: var(--background-color);
  }
}

.navButton.active {
  background-color: var(--tint-color);
  color: var(--background-color);
}

.navButton svg {
  display: block;
}

div:global(.additional-navigation) .controlsContainer {
  position: static;
}

div:global(.additional-navigation) .StripControls {
  position: static;
  margin: 0;
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .StripControls {
    margin: 0 -52px;
  }
}

@media screen and (max-width: 500px) {
  .StripControls {
    display: none;
  }

  div:global(.additional-navigation) .StripControls {
    display: flex; /* Unhide */
    margin: 1rem;
    padding: 0;
  }

  div:global(.additional-navigation) {
    display: block;
  }
}
