.StripModal .backdrop {
  z-index: 2;
  background-color: black;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  transition: opacity 400ms ease-in-out;
}

.StripModal.opened .backdrop {
  opacity: 0.4;
  cursor: pointer;
  visibility: visible;
}

.StripModal.closed .backdrop {
  opacity: 0;
  pointer-events: none;
}

.StripModal.closed .contents {
  display: none;
}

.StripModal .contents {
  z-index: 3;
  position: absolute;
  background-color: var(--background-color);
  top: 110px;
  left: 0;
  right: 0;

  -webkit-box-shadow: 0px -8px 20px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px -8px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px -8px 20px 0px rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
}
