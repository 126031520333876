.fieldWrapper {
  border: 1px solid var(--text-color);
  display: flex;
  align-items: baseline;
  background-color: var(--background-color);
  border-radius: 4px;
}

.label {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 8px;
  color: var(--tint-color);
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transition-property: max-width, padding;
}

/* .label.hidden {
  max-width: 0;
  padding-left: 0;
  padding-right: 0;
} */

.fieldDiv {
  flex-grow: 1;
  flex-shrink: 1;
}

.field {
  box-sizing: border-box;
  width: 100%;
  font-family: "Rockwell", "Sanchez", serif;
  color: var(--text-color);
  font-size: 1em; /* not smaller to prevent zoom on Safari iOS */
  padding: 12px 8px;
  border: 0;
  background-color: var(--clear-color);
  transition: background-color 125ms ease-in-out;
  border-radius: 4px;
}

select.field {
  appearance: none;
}

.field::placeholder {
  color: var(--gray-2);
}

.field:hover,
.field:focus {
  outline: none;
  background-color: var(--clear-color);
}

.field:disabled,
.field:disabled:hover {
  box-shadow: none;
  background-color: var(--gray-3);
}
