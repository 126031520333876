.hero {
  display: flex;
}

.coverContainer,
.contents {
  flex: 1 1 0;
}

.coverContainer {
  display: flex;
  place-content: center;
  padding-right: 30px;
}

.cover {
  max-width: 100%;
  max-height: 70vh;
  width: auto;
  height: auto;
}

.contents {
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  align-items: flex-start;
}

.contents h1 {
  margin: 0 0 20px;
  flex-grow: 0;
}

.contents .body {
  flex-grow: 1;
}

.contents p,
.contents ul,
.contents ol {
  margin: 0 0 12px;
}

.orderInfo {
  margin-top: calc(40px - 1rem);
  flex-grow: 0;
}

.orderInfo > * {
  display: block;
  margin-top: 1rem;
}

.orderButton {
  width: 100%;
}

ul.info {
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
  font-size: 14px;
  line-height: 21px;
}

.label {
  color: var(--gray-1);
}

.BookQuotes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.BookQuotes blockquote {
  margin: 1em 20px;
  max-width: 400px;
}

@media (max-width: 650px) {
  .hero {
    flex-direction: column;
  }
  .coverContainer {
    padding: 0;
  }
  .hero h1 {
    margin-top: 1em;
  }
}

@media (max-width: 450px) {
  .orderInfo {
    align-self: stretch;
  }
}
