ul.tags {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

ul.tags li {
  background-color: var(--gray-3);
  border-radius: 2px;
}

ul.tags li a {
  display: inline-block;
  padding: 0.2rem 0.33rem;
  font-size: 0.8em;
  text-decoration: none;
  color: var(--gray-1);
}

ul.tags li a:hover {
  color: var(--text-color);
}
