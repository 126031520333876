.avatarContainer {
  position: relative;
}

.avatarContainer picture {
  display: block;
}

.badge {
  position: absolute;
  top: -6px;
  left: -6px;
  font-size: 1.2rem;
  background-color: var(--alternate-tint-color);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge a {
  display: block;
  text-decoration: none;
}

.avatar,
.circle {
  box-sizing: border-box;
  border: 1px solid var(--gray-3);
  background-color: var(--gray-3);
  border-radius: 50%;
  color: var(--background-color);
  display: block;
  flex-grow: 0;
}

.withBadge .avatar {
  border: 1px solid var(--alternate-tint-color);
  background: none;
}

.avatar.large,
.circle.large {
  --avatar-size: 120px;
}

.avatar {
  width: var(--avatar-size, 64px);
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.circle {
  width: var(--avatar-size, 64px);
  height: var(--avatar-size, 64px);
  text-align: center;
}

.initials {
  font-size: calc(var(--avatar-size, 64px) / 2);
  line-height: 1;
  position: relative;
  top: calc(var(--avatar-size, 64px) / 4);
  cursor: default;
}

a .initials {
  cursor: pointer;
}
