.Spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.3rem solid var(--tint-color);
  border-right: 0.3rem solid transparent;
  border-radius: 50%;
  animation: spinner-border 1s linear infinite;
}

.Spinner.small {
  width: 1rem;
  height: 1rem;
  border-width: 0.2rem;
}

footer .Spinner,
header .Spinner {
  border-color: var(--background-color);
}

@keyframes spinner-border {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 60px 0;
}
