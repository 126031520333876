.FeedGallery {
  background-color: var(--brand-background-color);
  color: var(--background-color);
  --shadow-color: var(--tint-color);
  display: flex;
  justify-content: center;
}

@media (prefers-color-scheme: dark) {
  .FeedGallery {
    --shadow-color: unset;
  }
}

.FeedGallery.header {
  margin-top: -10px;
}
.FeedGallery.footer {
  margin: 0;
  padding: 40px 0 0;
}

.scrollable {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  padding-bottom: 20px;
}

.FeedGallery.header .scrollable {
  padding-bottom: 30px;
}

.section {
  margin: 0;
  padding: 10px 20px 0 20px;
  border-left: 1px solid var(--shadow-color);
}

.section:first-child {
  margin-left: 40px;
  padding-left: 0;
  border-left: 0;
}

.section:last-child {
  padding-right: 40px;
}

.section h3 {
  margin-top: 0;
  white-space: nowrap;
}

.FeedGallery a {
  color: var(--background-color);
  text-decoration: none;
}

.FeedGallery ul a {
  display: block;
}

.section h3 a {
  text-decoration: underline;
}

.FeedGallery ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: last baseline;
  list-style-type: none;
  gap: 20px;
}

.FeedGallery li {
  display: inline-block;
  flex: 0 0 auto;
}

.FeedGallery li:last-child {
  padding-right: 0;
}

.FeedGallery img {
  max-height: 150px;
}

@media screen and (max-width: 760px) {
  .FeedGallery img {
    max-height: 116px;
  }

  .section:first-child {
    margin-left: 20px;
  }

  .section:last-child {
    padding-right: 20px;
  }
}
