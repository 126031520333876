.Checkout {
  display: grid;
  grid-template-columns: minmax(500px, 720px) 280px;
  column-gap: 60px;
  justify-content: center;
}

.Checkout h1,
.Checkout h2 {
  margin-top: 0;
  line-height: 34px;
}

.shipment form {
  width: 100%;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
}

.Checkout form > * {
  grid-column: 1 / -1;
}

.Checkout form .halfWidth {
  grid-column: initial;
}

.payment {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.summary h2 {
  margin-bottom: 12px;
}

.loginPrompt {
  font-size: 0.8em;
}

.summary {
  flex-grow: 1;
}

.paymentButtonWrapper {
  flex-grow: 0;
}

.paymentButtonWrapper button {
  width: 100%;
  margin: 0;
}

.checkbox {
  display: block;
  margin: 8px 0;
}

@media (max-width: 960px) {
  .Checkout {
    grid-template-columns: 1fr;
  }

  .payment {
    margin-top: 0;
  }
}

@media (max-width: 640px) {
  .shipment form {
    grid-template-columns: 1fr;
  }
}
