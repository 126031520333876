.Gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, 202px);
  grid-auto-flow: row dense;
  grid-gap: 20px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.stripOpen {
  max-height: 10px;
  overflow: hidden;
}

.fullRow {
  justify-self: stretch;
  align-self: stretch;
  grid-column: 1 / -1;
}

.book {
  border: 2px solid #f2de96;
  border-left: none;
  border-right: none;
  padding: 20px 0;
  display: flex;
}

.book > div {
  width: 100%;
}

@media (max-width: 727px) {
  .Gallery {
    grid-template-columns: auto auto auto;
  }
}

@media screen and (max-width: 550px) {
  .Gallery {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
}

@media (max-width: 300px) {
  .Gallery {
    grid-template-columns: 1fr;
  }
}
