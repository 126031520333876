.headerWithButtons {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 12px;
}

.headerWithButtons h1,
.headerWithButtons h2,
.headerWithButtons h3 {
  margin: 0 0.8rem 0 0 !important;
}

.headerWithButtons div {
  font-size: 12px;
  color: var(--gray-1);
}

.headerWithButtons div a {
  color: inherit;
}

@media (max-width: 500px) {
  .headerWithButtons {
    flex-direction: column;
  }

  .headerWithButtons h1,
  .headerWithButtons h2,
  .headerWithButtons h3 {
    margin: 0;
  }
}
