.pagination {
  display: flex;
  margin: 0;
  padding: 0;
}

.pagination li {
  display: inline-block;
  list-style-type: none;
}

.pagination li a {
  text-decoration: none;
  display: inline-block;
  padding: 2px 8px;
  border-radius: 4px;
}

.pagination li:first-child a:not(.active) {
  padding-left: 0;
}

.pagination li a.active {
  color: var(--clear-color);
  background-color: var(--tint-color);
}

.pagination button {
  border: 0;
  background: none;
}
