.navigationBar {
  background-color: var(--background-color);
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--brand-background-color);
  margin-bottom: 20px;
}

.content {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  gap: 12px;
}

.separator {
  border-right: 1px solid var(--brand-background-color);
  align-self: stretch;
  flex-grow: 0;
  flex-shrink: 0;
}

.sections {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
}

.search {
  padding: 8px 0;
  flex-grow: 0;
  flex-shrink: 0;
  color: var(--tint-color);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancel-button {
  border: 0;
  background: none;
  color: var(--tint-color);
  min-width: 2rem;
  padding: 0;
  font-size: 1.25em;
  cursor: pointer;
}

.cancel-button svg {
  vertical-align: middle;
}

.yearSelector {
  display: none;
  border: 0;
  border-radius: 6px;
  background: var(--tint-color);
  color: var(--background-color);
  margin: 4px 0;
  padding: 3px 6px;
}

.yearSelector:global(.no-value) {
  background: none;
  color: var(--tint-color);
}

@media (max-width: 500px) {
  .yearSelector {
    display: block;
  }

  .sections {
    display: none;
  }
}

@media (max-width: 319px) {
  .sections {
    flex-basis: 0;
  }
  .search {
    max-width: 100%;
  }
  .search input {
    width: 100%;
  }
}
